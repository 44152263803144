<import from="./cp-header" />
<import from="./cp-hero" />
<import from="./cp-about" />
<import from="./cp-hosting" />
<import from="./cp-portfolio" />
<import from="./cp-footer" />

<cp-header></cp-header>
<cp-hero></cp-hero>

<main id="main">
  <cp-about></cp-about>
  <cp-hosting></cp-hosting>
  <cp-portfolio></cp-portfolio>
</main>

<cp-footer></cp-footer>

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"
  ><i class="bi bi-arrow-up-short"></i
></a>
