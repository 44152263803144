var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as __au2ViewDef from './cp-portfolio.html';
import { customElement } from '@aurelia/runtime-html';
let CpPortfolio = class CpPortfolio {
    goExternal(link) {
        //location.href = link
        // or
        // api https://developer.mozilla.org/en-US/docs/Web/API/Window/open
        window.open(link, '_blank', 'noreferrer');
    }
    goRelative(link) {
        var relativeWindow = window.open(link, '_blank', 'noreferrer');
        relativeWindow.location.replace(link);
    }
};
CpPortfolio = __decorate([
    customElement(__au2ViewDef)
], CpPortfolio);
export { CpPortfolio };
