<!-- ======= Hosting Section ======= -->
<section id="hosting" class="about">
  <div class="container">
    <div class="section-title" data-aos="zoom-out">
      <h2>Hosting</h2>
      <p>Where the code runs</p>
    </div>

    <div class="row content" data-aos="fade-up">
      <div class="col-lg-6">
        <p>
          We mostly host in
          <a href="https://aws.amazon.com/" target="_blank">Aws</a> but the
          cloud is not as cheap as everyone claims. To get around the cost we
          make use of
          <a href="https://client.absolutehosting.co.za/aff.php?aff=206"
            >Absolute Hosting</a
          >
          as an alternative. If you want to reward us for making you aware of an
          affordable hosting partner then please use our affiliate link and
          signup for really cheap but reliable VPS servers and Website hosting.
        </p>
        <ul>
          <li>
            <i class="ri-check-double-line"></i>
            <a href="https://aws.amazon.com/" target="_blank">Aws</a>
          </li>
          <li>
            <i class="ri-check-double-line"></i>
            <a
              href="https://client.absolutehosting.co.za/aff.php?aff=206"
              target="_blank"
              >Absolute Hosting</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0">
        <p></p>
      </div>
    </div>
  </div>
</section>
<!-- End Portfolio Section -->
