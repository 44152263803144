<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio">
  <div class="container">
    <div class="section-title" data-aos="zoom-out">
      <h2>Portfolio</h2>
      <p>What we've done</p>
    </div>

    <ul
      id="portfolio-flters"
      class="d-flex justify-content-end"
      data-aos="fade-up"
    >
      <li data-filter="*" class="filter-active">All</li>
      <li data-filter=".filter-app">App</li>
      <li data-filter=".filter-web">Web</li>
      <li data-filter=".filter-iot">IOT</li>
    </ul>

    <div class="row portfolio-container" data-aos="fade-up">
      <div class="col-lg-4 col-md-4 portfolio-item filter-app">
        <div class="portfolio-img">
          <img
            src="src/assets/img/portfolio/ph_drugwars.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="portfolio-info">
          <h4>DrugWars</h4>
          <p>
            A mobile drug trading game based on the original &nbsp; created by
            John E. Dell in 1984. &nbsp;
            <br />
            <a
              click.capture="goRelative('/src/assets/apps/com.codeprospect.games.drugwars.apk')"
              title="Android App"
              >Android Download<i class="bx bx-link"></i
            ></a>
            &nbsp;
            <a
              click.capture="goRelative('/src/assets/apps/com.codeprospect.games.drugwars.ios.ipa')"
              title="iOS App"
              >iOS Download<i class="bx bx-link"></i
            ></a>
          </p>
          <a
            href="src/assets/img/portfolio/ph_drugwars.jpg"
            data-gallery="portfolioGallery"
            class="portfolio-lightbox preview-link"
            title="DrugWars"
            ><i class="bx bx-plus"></i
          ></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 portfolio-item filter-web">
        <div class="portfolio-img">
          <img
            src="src/assets/img/portfolio/ph_flappy.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="portfolio-info">
          <h4>Flappy Dragon</h4>
          <p>
            A rust based flappy bird clone compiled to wasm <br />
            based on Hands-On-Rust by Herbert Wolverson.
          </p>
          <a
            href="src/assets/img/portfolio/ph_flappy.jpg"
            data-gallery="portfolioGallery"
            class="portfolio-lightbox preview-link"
            title="Flappy Dragon"
            ><i class="bx bx-plus"></i
          ></a>
          <a
            click.capture="goExternal('http://flappy.codeprospect.co.za')"
            class="details-link"
            title="Play Game"
            ><i class="bx bx-link"></i
          ></a>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 portfolio-item filter-iot">
        <div class="portfolio-img">
          <img
            src="src/assets/img/portfolio/ph_sensesound.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="portfolio-info">
          <h4>SenseSound</h4>
          <p>
            SenseSound is a IoT sound monitor that features <br />
            alerts when decibels go over thresholds.
          </p>
          <a
            href="src/assets/img/portfolio/ph_sensesound.jpg"
            data-gallery="portfolioGallery"
            class="portfolio-lightbox preview-link"
            title="SenseSound"
            ><i class="bx bx-plus"></i
          ></a>
          <a
            click.capture="goRelative('/src/assets/files/SenseSoundUserManual.pdf')"
            class="details-link"
            title="PDF Manual"
            ><i class="bx bx-link"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Portfolio Section -->
