<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="container">
    <h3>CodeProspect</h3>
    <p>Your partner in coding success.</p>
    <div class="social-links">
      <a
        href="https://www.linkedin.com/company/codeprospect"
        target="_blank"
        class="linkedin"
        ><i class="bx bxl-linkedin"></i
      ></a>
    </div>
    <div class="copyright">
      &copy; Copyright <strong><span>CodeProspect</span></strong
      >. All Rights Reserved
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/selecao-bootstrap-template/ -->
      Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
    </div>
  </div>
</footer>
<!-- End Footer -->
