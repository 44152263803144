import Aurelia, { LoggerConfiguration, ConsoleSink, RouterConfiguration } from 'aurelia';
import { Codeprospect } from './views/codeprospect';
Aurelia
    .register(LoggerConfiguration.create({
    level: 1 /* debug */,
    sinks: [ConsoleSink],
}), RouterConfiguration.customize({
    useUrlFragmentHash: false,
}))
    .app(Codeprospect)
    .start();
