<!-- ======= About Section ======= -->
<section id="about" class="about">
  <div class="container">
    <div class="section-title" data-aos="zoom-out">
      <h2>About</h2>
      <p>Who we are</p>
    </div>

    <div class="row content" data-aos="fade-up">
      <div class="col-lg-6">
        <p>
          Currently the company is a vehicle for holding the coding projects and
          financial assets developed part time. Not everyting is being done for
          profit. Here are some of the areas of interest.
        </p>
        <ul>
          <li><i class="ri-check-double-line"></i> Agri Techonology</li>
          <li><i class="ri-check-double-line"></i> Game development</li>
          <li><i class="ri-check-double-line"></i> Electronics</li>
        </ul>
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0">
        <p></p>
      </div>
    </div>
  </div>
</section>
<!-- End About Section -->
